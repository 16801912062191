/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback, useState, useEffect } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import HtmlDiv from "sites-common/components/HtmlDiv";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/general-fund-group.png";

import yoga from "../../static/imgs/yoga-meditation.png";
import environment from "../../static/imgs/environment.png";
import greenInitiative from "../../static/imgs/green_initiative_forests_by_heartfulness_1.png";
import leaves from "../../static/imgs/leaves.png";
import fieldsImg from "../../static/imgs/fieldsImg.png";
import yogaTeacher from "../../static/imgs/yoga-teacher-training.png";
import htcStudent from "../../static/imgs/hcstudent-slide.png";
import globalEvent from "../../static/imgs/global-essay-event.png";
import hcp from "../../static/imgs/hcp-img.png";
import dasaraEvent from "../../static/imgs/the-heartfulness-dasara-event.png";
import help from "../../static/imgs/help-program.png";
import yogaLesson from "../../static/imgs/yoga-lesson.jpg";
import groupYoga from "../../static/imgs/group_yoga.jpg";
import selfYoga from "../../static/imgs/self_yoga.jpg";
import educationalInitiative from "../../static/imgs/educational-initiative.png";

const GeneralFund = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness General Fund";
    return `<gatsby_donation donationId="${id}" btntext="${titletext}"
    btnBg=#ea5232 colortext = navy colorprimary = #085788
    colorsecondary = midnightblue colorbackground = white
    colormuted = #f6f6f6 colorhighlight = #efeffe titletext =
    "Heartfulness General Fund" meditationPlaceFieldAvl="true" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_General_Fund", event),
    []
  );

  const [activeTab, setActiveTab] = useState("environment");
  const [activeTabContent, setActiveTabContent] = useState();
  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    const tabString = {
      environment: {
        title: "Forests by Heartfulness",
        paraOne:
          "<p>Our primary goal is to plant at-least 30 million native and endemic trees across India by 2025.</p><p>In order to achieve this goal, a team of forestry, agro-forestry and climate experts are guiding the project and will continue to do so for the next 5 years. Under their guidance, as a first step, nurseries are being developed in 18 cities across the country.</p>",
        environmentImg: `${environment}`,
        sections: [
          {
            sectionImage: `${greenInitiative}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/forests/' target='blank' rel='noopener noreferrer'>Forests by Heartfulness</a></b>",
            sectionDesc: "<p>Trees Planted So Far: <br /><b>1,029,891</b></p>",
          },
          {
            sectionImage: `${leaves}`,
            sectionTitle: "<b>Conservation Center at Kanha</b>",
            sectionDesc: "Tissue-culture based Tree Conservation",
          },
          {
            sectionImage: `${fieldsImg}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/kanha/green-initiatives/' target='blank' rel='noopener noreferrer'>Green Kanha Initiative</a></b>",
            sectionDesc:
              "<p><b>800K trees</b><br/>planted and hundreds of species being conserved</p>",
          },
        ],
      },
      education: {
        title: "Educational Initiatives",
        paraOne:
          "<p>Heartfulness Education enables teachers and students to live a balanced, purposeful, and a happy life using heart-based practices, so that they are equipped to contribute towards a sustainable school reform and community citizenship.</p>",
        environmentImg: `${educationalInitiative}`,
        sections: [
          {
            sectionImage: `${yogaTeacher}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/education/training-and-curriculum/' target='blank' rel='noopener noreferrer'>Heartfulness Way</a></b>",
            sectionDesc: "Curricula for K12 and INSPIRE Teacher Training",
          },
          {
            sectionImage: `${htcStudent}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/education/gitopadesh-courses/' target='blank' rel='noopener noreferrer'>Gitopadesh</a></b>",
            sectionDesc: "Curricula and Content for Children",
          },
          {
            sectionImage: `${globalEvent}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/education/essay-event/' target='blank' rel='noopener noreferrer'>Heartfulness Global Essay Event</a></b>",
            sectionDesc: "",
          },
          {
            sectionImage: `${help}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/education/help/' target='blank' rel='noopener noreferrer'>HELP Program</a></b>",
            sectionDesc:
              "(Life-skills program for vulnerable and underprivileged adolescents)",
            treeCount: "",
          },
          {
            sectionImage: `${dasaraEvent}`,
            sectionTitle: "<b>Heartful Campus</b>",
            sectionDesc:
              "(Heart-based Foundational Leadership Curricula) for Universities and Colleges",
            treeCount: "",
          },
          {
            sectionImage: `${hcp}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/hc/' target='blank' rel='noopener noreferrer'>Heartful Communication Programs</a></b>",
            sectionDesc: "",
            treeCount: "",
          },
        ],
      },
      wellness: {
        title: "Yoga and Wellness Initiatives",
        paraOne:
          "<p>The Heartfulness Institute offers Meditation, Yoga, Kaushalam & Wellness Retreats  to one and all. Ancient traditions and modern science are brought together in these programs to bring balance and harmony in modern lifestyles and promote well-being across the world.</p>",
        environmentImg: `${yoga}`,
        sections: [
          {
            sectionImage: `${yogaLesson}`,
            sectionTitle:
              "<b><a href='https://heartfulness.org/yoga/' target='blank' rel='noopener noreferrer'>Heartfulness Yoga Academy</a></b>",
            sectionDesc: "",
            treeCount: "",
          },
          {
            sectionImage: `${groupYoga}`,
            sectionTitle: "<b>Yoga Day Celebrations around the world</b>",
            sectionDesc: "",
            treeCount: "",
          },
          {
            sectionImage: `${selfYoga}`,
            sectionTitle:
              "<b><a href='https://www.youtube.com/@HeartfulnessWellness' target='blank' rel='noopener noreferrer'>Wellness Webinars</a></b>",
            sectionDesc: "",
            treeCount: "",
          },
        ],
      },
    };

    if (activeTab === "environment") {
      setActiveTabContent(tabString.environment);
    } else if (activeTab === "education") {
      setActiveTabContent(tabString.education);
    } else {
      setActiveTabContent(tabString.wellness);
    }
  }, [activeTab]);

  useEffect(() => {
    windowSizeTracker();
  }, []);

  return (
    <>
      <DonationLayout
        seoData={{ title: "Heartfulness General Fund" }}
        isGeneralFund
      >
        <div className="banner-gatsby">
          <Box
            sx={{
              height: "331px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${banner})`,
            }}
          />
          <div
            sx={{
              textAlign: "center",
              padding: "8px 15px",
              background: " #F8F8F9DE 0% 0% no-repeat padding-box",
            }}
          >
            <h2
              sx={{
                color: "#085788",
                fontWeight: "600",
                padding: "10px 0px 5px 0px",
              }}
            >
              Heartfulness - One Humanity. One Heart.
            </h2>
            <div
              style={{
                padding: isMobile ? "0px 20px" : "0px 95px",
                textAlign: isMobile && "left",
              }}
            >
              <p>
                Join Hearts and Hands with us as we take meditation to every
                Heart and every Home, free of charge. Thanks to the donations
                from our global community of practitioners we are developing and
                maintaining hundreds of facilities such as meditation centers,
                retreats, and research centers around the world besides
                thousands of local events to promote the benefits of Meditation
                and Yoga.{" "}
              </p>
              <p>
                {" "}
                Thank you for considering a donation to the Heartfulness
                Institute. Your contributions will help individuals find inner
                peace, clarity, and purpose in their lives.
              </p>
            </div>
            <div sx={{ fontSize: "20px" }}>
              <Box>
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(2, "Contribute Now")}
                />
              </Box>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div sx={{ paddingTop: "20px", textAlign: "center" }}>
              <h2
                sx={{
                  color: "#085788",
                  fontWeight: "600",
                }}
              >
                Global Impact Initiatives
              </h2>
              <Box
                sx={{
                  width: "50%",
                  margin: "40px auto 30px auto",
                }}
              >
                <Grid columns={[1, 1, 3, null]}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      sx={{
                        width: "9rem",
                        padding: "5px 10px",
                        color: "#363533",
                        border: " 1.5px solid white",
                        ...(activeTab === "environment" && {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }),
                        ":hover": {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        },
                      }}
                      onKeyDown={() => setActiveTab("environment")}
                      onClick={() => setActiveTab("environment")}
                      role="button"
                      tabIndex={0}
                    >
                      <b
                        sx={{
                          opacity: "1",
                        }}
                      >
                        Environment
                      </b>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      sx={{
                        width: "9rem",
                        padding: "5px 10px",
                        color: "#363533",
                        border: " 1.5px solid white",
                        ...(activeTab === "education" && {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }),
                        ":hover": {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => setActiveTab("education")}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setActiveTab("education")}
                    >
                      <b
                        sx={{
                          opacity: "1",
                        }}
                      >
                        Education
                      </b>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      sx={{
                        width: "9rem",
                        padding: "5px 10px",
                        color: "#363533",
                        border: " 1.5px solid white",
                        ...(activeTab === "wellness" && {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }),
                        ":hover": {
                          color: "#005EC2",
                          border: " 1.5px solid #033B77",
                          borderRadius: "5px",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => setActiveTab("wellness")}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setActiveTab("wellness")}
                    >
                      <b
                        sx={{
                          opacity: "1",
                        }}
                      >
                        Wellness
                      </b>
                    </div>
                  </Box>
                </Grid>
              </Box>
              <Box>
                <div
                  sx={{
                    top: "948px",
                    left: "158px",
                    minHeight: "125px",
                    background: "#E9F4FF 0% 0% no-repeat padding-box",
                    border: "1px solid #005EC2",
                    borderRadius: "5px",
                    opacity: "1",
                  }}
                >
                  <div
                    sx={{
                      padding: "25px 25px 15px 25px",
                      textAlign: "left",
                      letterSpacing: "0px",
                      color: "#033B77",
                      opacity: "1",
                    }}
                  >
                    <p
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      We are also taking the inspiration of Heartfulness towards
                      various programs and initiatives in the areas of
                      education, environment, and yoga. By making a donation to
                      the Heartfulness Institute, you are investing in a
                      brighter and more peaceful future for everyone.
                    </p>
                  </div>{" "}
                </div>
              </Box>
            </div>
          </div>
        </div>
        {[activeTabContent]?.map((item) => {
          return (
            <div className="container" sx={{ padding: "30px 25px 0px 25px" }}>
              <Box>
                <Box>
                  <div className="row">
                    <Box>
                      <div
                        sx={{
                          color: "#363533",
                        }}
                      >
                        <h5>
                          <b
                            sx={{
                              opacity: "1",
                              color: "#363533",
                            }}
                          >
                            {item?.title}
                          </b>
                        </h5>
                      </div>
                    </Box>
                    <div className="col-md-8" sx={{ paddingTop: "15px" }}>
                      <Box>
                        <HtmlDiv htmlString={item?.paraOne} />
                      </Box>
                    </div>
                    <div className="col-md-4">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {item?.environmentImg && (
                          <img
                            src={item?.environmentImg}
                            alt="Logo"
                            sx={{ height: "261px", width: "362px" }}
                          />
                        )}
                      </Box>
                    </div>
                  </div>
                </Box>
                <Box sx={{ paddingTop: "25px" }}>
                  <div className="row">
                    {item?.sections?.map((sectionItem) => {
                      return (
                        <div
                          className="col-md-4"
                          sx={{ paddingBottom: "30px" }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              padding: "0px 8px 0px 8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={sectionItem?.sectionImage}
                                alt="Logo"
                                sx={{
                                  height: "190px",
                                  width: "326px",
                                  paddingBottom: "15px",
                                }}
                              />
                            </Box>
                            <p sx={{ color: "#363533" }}>
                              <HtmlDiv
                                htmlString={sectionItem?.sectionTitle}
                                sx={{
                                  color: "#288bd0 !important",
                                }}
                              />
                            </p>
                            <div sx={{ padding: "0px 15px" }}>
                              <HtmlDiv
                                htmlString={sectionItem?.sectionDesc}
                                style={{
                                  color: "#363533",
                                }}
                              />
                            </div>
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </Box>
            </div>
          );
        })}

        <div
          sx={{
            textAlign: "center",
            paddingTop: "30px",
            letterSpacing: "0px",
            color: "#363533",
            opacity: "1",
          }}
        >
          <h5 sx={{ fontWeight: "bolder" }}>
            <b>We request your generous donations to fund this cause</b>
          </h5>
        </div>

        <div className="text-center" sx={{ paddingBottom: "15px" }}>
          <div sx={{ fontSize: "20px" }}>
            <Box>
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(2, "Contribute Now")}
              />
            </Box>
          </div>
        </div>
        <div
          sx={{
            width: "100%",
            padding: "20px 10px",
            textAlign: "center",
            background: "#005ec1",
            color: "#FFFFFF",
          }}
        >
          <div className="container">
            <p className="q-txt" sx={{ fontSize: "16px" }}>
              For all queries and interests to contribute / initiate General
              Fund initiatives in collaboration with Heartfulness Institute,
              please write to
            </p>
            <p sx={{ fontSize: "22px", margin: "15px 0 0", color: "#FFFFFF" }}>
              <a sx={{ color: "white" }} href="mailto:info@heartfulness.org">
                info@heartfulness.org
              </a>
            </p>
          </div>
        </div>
      </DonationLayout>
    </>
  );
};

export default GeneralFund;
